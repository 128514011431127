<template>
  <b-container class="bg-white py-4">
    <b-row v-if="!permissionAdd">
      <b-col>
        <b-card>
          <h4>You do not have access permission for this page</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="permissionAdd">
      <b-col>
        <b-container>
          <b-row class="mb-4" v-if="firststep">
            <b-col>
              <h4>Adding new comms</h4>
              <div class="mb-2"><strong>Comms body</strong></div>
                <b-form-textarea id="bodyInput"
                                v-model="row.body"
                                placeholder="Comms content"
                                required
                                rows=4
                                class="mb-2">
                </b-form-textarea>
                <div class="mb-2"><strong>Risks</strong></div>
                <b-form-input   id="riskInput"
                                v-model="row.risks"
                                placeholder="Risks"
                                required
                                class="mb-2">
                </b-form-input>

                <div class="mb-2"><strong>Detection difficulty</strong></div>
                <b-form-select class="mb-2" v-model="row.difficulty" :options="options" />

              <div class="mb-2"><strong>Comments</strong></div>
                <b-form-textarea id="detailsInput"
                                v-model="row.comments"
                                placeholder="Comments"
                                required
                                rows=4
                                class="mb-2">
                </b-form-textarea>
                <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="preview">Preview</b-button>
                <b-button variant="outline-primary" size="sm" :to="{ name: 'Comms' }">Cancel</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="previewstep">
            <b-col>
              <div>Content: {{row.body}}</div>
              <div>Risks: {{row.risks}}</div>
              <div>Detection difficulty: {{row.difficulty}}</div>
              <div>Comments: {{row.comments}}</div>
              <span v-if="!saving">
                <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="save">Save</b-button>
                <b-button variant="outline-primary" size="sm" v-on:click="edit">Edit</b-button>
              </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="finalstep">
            <b-col>
              <h4>Created</h4>
              <div>Content: {{row.body}}</div>
              <div>Risks: {{row.risks}}</div>
              <div>Detection difficulty: {{row.difficulty}}</div>
              <div>Comments: {{row.comments}}</div>
              <div>Created: {{row.created_at}}</div>
              <div>User: {{row.user}}</div>
              <!-- <b-button variant="primary" size="lg" :to="{ name: 'AlertEdit', params: { id: row.id }}">Continue to edit details</b-button> -->
              <b-button variant="outline-primary" size="sm" :to="{ name: 'Comms' }">Back to communications</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '../libs/accesscontrol'

export default {
  name: 'CommAdd',
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.row.body && this.row.comments && this.row.risks
    }
  },
  created: function () {
    this.permissionAdd = ac.can(this.user.acgroups).createAny('comm').granted
  },
  data () {
    return {
      firststep: true,
      finalstep: false,
      options: [
        { value: 1, text: '1 - low' },
        { value: 2, text: '2 - medium' },
        { value: 3, text: '3 - high' }
      ],
      permissionAddRisk: false,
      previewstep: false,
      saving: false,
      rendered: {
        comments: null
      },
      row: {
        body: null,
        difficulty: 1,
        risks: null,
        comments: null,
        user: null
      }
    }
  },
  methods: {
    edit: function () {
      this.firststep = true
      this.previewstep = false
      this.finalstep = false
    },
    preview: function () {
      this.firststep = false
      this.finalstep = false
      this.previewstep = true
    },
    save: async function () {
      this.$logger.debug(`save start`)
      this.previewstep = false
      this.saving = true
      this.row.user = this.user.username
      try {
        // let response = await this.$http(config)
        let apiName = 'sql'
        let path = `/comms`
        let params = {
          body: this.row
        }
        let response = await this.$Amplify.API.post(apiName, path, params)
        this.$stat.log({ page: 'comm', action: 'created comm', model: 'comm', model_id: response.id, payload: response })
        this.$logger.debug('saved OK:', response)
        this.row = response
        this.finalstep = true
      } catch (e) {
        this.$logger.warn('row saving error: ' + e)
      }
      this.saving = false
    }
  }
}
</script>

<style>
</style>
